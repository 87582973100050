"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlStringify = exports.getParam = exports.getCookie = exports.getToken = exports.getUserId = void 0;
/**
 * 获取工号 和 token
 * @returns
 */
function getUserId() {
    return getParam('empNo') || getCookie('PORTALSSOUser') || getCookie('ZTEDPGSSOUser');
}
exports.getUserId = getUserId;
function getToken() {
    return getParam('token') || getCookie('PORTALSSOCookie') || getCookie('ZTEDPGSSOCookie');
}
exports.getToken = getToken;
/**
 * 根据key获取cookie值
 */
function getCookie(key) {
    return decodeURIComponent(findHashValue(document.cookie, key, '; '));
}
exports.getCookie = getCookie;
/**
 * 获取url中参数
 * @param {string} name
 * @param {string} href
 */
function getParam(name, href) {
    if (href === void 0) { href = window.location.href; }
    var search = href.substring(href.indexOf('?') + 1);
    return decodeURIComponent(findHashValue(search, name, '&'));
}
exports.getParam = getParam;
function findHashValue(str, name, separation) {
    if (separation === void 0) { separation = '&'; }
    if (!str || !name) {
        return '';
    }
    str = separation + str + separation;
    var searcher = separation + name + '=';
    var index = str.lastIndexOf(searcher);
    if (index === -1) {
        return '';
    }
    return str.slice(index + searcher.length, str.indexOf(separation, index + 1));
}
/**
 * 对象转成url参数（&连接）
 */
function urlStringify(object) {
    var keys = Object.keys(object);
    return keys
        .map(function (key) {
        var value = object[key];
        if (value === undefined) {
            return '';
        }
        if (value === null) {
            return key;
        }
        return key + '=' + encodeURIComponent(value);
    })
        .filter(function (x) { return x.length > 0; })
        .join('&');
}
exports.urlStringify = urlStringify;
