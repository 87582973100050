"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("./util");
function connectWebViewJavascriptBridge(callback) {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    if (/iPhone|iPad/i.test(userAgent)) {
        //iOS使用
        if (window.WebViewJavascriptBridge) {
            return callback(WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () {
            document.documentElement.removeChild(WVJBIframe);
        }, 0);
    }
    else {
        if (window.WebViewJavascriptBridge) {
            callback(WebViewJavascriptBridge);
        }
        else {
            document.addEventListener('WebViewJavascriptBridgeReady', function () {
                callback(WebViewJavascriptBridge);
            }, false);
        }
    }
}
// JS 桥
function bindMobile(init) {
    connectWebViewJavascriptBridge(init);
}
// web 模拟js桥
function bindWeb(init) {
    window.WebViewJavascriptBridge = {
        callHandler: function (operate, param) {
            switch (operate) {
                case 'closePage':
                    location.reload();
                    break;
                default:
                    break;
            }
        }
    };
    init(window.WebViewJavascriptBridge);
}
exports.default = {
    bind: function (init) {
        window.bridge = {
            hideHeader: function (callback) {
                window.WebViewJavascriptBridge.callHandler('topBarVisible', { visible: '1' }, callback);
            },
            openNativeContent: function (spaceId, contentId, contentTitle) {
                window.WebViewJavascriptBridge.callHandler("openNativeContent", {
                    spaceId: spaceId,
                    contentId: contentId,
                    contentTitle: contentTitle
                }, function () { });
            },
            closePage: function () {
                window.WebViewJavascriptBridge.callHandler("closePage", {
                    isRefesh: "0"
                }, function () { });
            }
        };
        // URL参数type决定绑定js桥类型，仅当为web时绑定web的js桥
        if ((0, util_1.getParam)('type') === 'web') {
            bindWeb(init);
        }
        else {
            bindMobile(init);
        }
    }
};
